import { axiosClient } from "clients/axios";

import {
  PendingPaymentResponse,
  PendingPaymentUpdateParams,
  PurchaseStripeProductParams,
  StripeCustomerCreateParams,
  StripeCustomerCreateResponse,
  StripeCustomerUpdateParams,
  StripeCustomerUpdateResponse,
  StripePromoCodeFetchResponse,
  StripeSubscriptionCreateParams,
  StripeSubscriptionCreateResponse,
  StripeSubscriptionUpdateParams,
  StripeSubscriptionUpdateResponse,
  SubscriptionPlanInfo,
  StripePurchaseResponse,
  PendingPaymentAdditionUpdateParams,
  RenewalDateUpdateParams,
  PendingPaymentCreateParams,
} from "types/api";

export const createStripeCustomer = async (
  values: StripeCustomerCreateParams
) => {
  const response = await axiosClient.post<StripeCustomerCreateResponse>(
    `payment/customer/${values.organization_id}`,
    values
  );

  return response.data;
};

export const updateStripeCustomer = async (
  organizationId: string,
  values: StripeCustomerUpdateParams
) => {
  const response = await axiosClient.patch<StripeCustomerUpdateResponse>(
    `payment/customer/${organizationId}`,
    values
  );

  return response.data;
};

export const createStripeSubscription = async (
  values: StripeSubscriptionCreateParams
) => {
  const response = await axiosClient.post<StripeSubscriptionCreateResponse>(
    `payment/subscription/${values.organization_id}/${values.plan_id}`,
    values
  );

  return response.data;
};

export const updateStripeSubscription = async (
  values: StripeSubscriptionUpdateParams
) => {
  const response = await axiosClient.patch<StripeSubscriptionUpdateResponse>(
    `payment/subscription/${values.organization_id}/${values.plan_id}`,
    values
  );

  return response.data;
};

export const getOrganizationSubscription = async (orgId: string) => {
  const response = await axiosClient.get<SubscriptionPlanInfo>(
    `organization/${orgId}/subscription-plan`
  );
  return response.data;
};

export const getPromotionCodeCoupon = async (promoCode: string) => {
  const response = await axiosClient.get<StripePromoCodeFetchResponse>(
    `payment/price/promoCode`,
    { params: { promo_code: promoCode } }
  );
  return response.data;
};

export const updatePendingPaymentForOrg = async (
  orgId: string,
  values: PendingPaymentUpdateParams
) => {
  const response = await axiosClient.patch<PendingPaymentResponse>(
    `payment/pending/${orgId}`,
    values
  );

  return response.data;
};

export const updateRenewalDateByOrg = async (
  orgId: string,
  values: RenewalDateUpdateParams
) => {
  const response = await axiosClient.patch<PendingPaymentResponse>(
    `payment/pending/${orgId}/renewal`,
    values
  );

  return response.data;
};

export const createExtraItemPayment = async (
  orgId: string,
  values: PendingPaymentAdditionUpdateParams
) => {
  const response = await axiosClient.post<PendingPaymentResponse>(
    `payment/pending/${orgId}/addition`,
    values
  );

  return response.data;
};
export const createPendingPayment = async (
  orgId: string,
  values: PendingPaymentCreateParams
) => {
  const response = await axiosClient.post<PendingPaymentResponse>(
    `payment/pending/${orgId}/`,
    values
  );

  return response.data;
};

export const updatePendingPaymentAdditionForOrg = async (
  orgId: string,
  values: PendingPaymentAdditionUpdateParams
) => {
  const response = await axiosClient.patch<PendingPaymentResponse>(
    `payment/pending/${orgId}/addition`,
    values
  );

  return response.data;
};

export const purchaseStripeProduct = async (
  values: PurchaseStripeProductParams
) => {
  const response = await axiosClient.post<StripePurchaseResponse>(
    `payment/purchase/${values.organization_id}`,
    values
  );

  return response.data;
};
