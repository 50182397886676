import React, { createContext, useContext, useReducer } from "react";

import { Column, Card } from "../types/Kanban";
import { KanbanContextAction } from "./KanbanContextActions";

import { objFromArray } from "utils/obj-from-array";

interface KanbanState {
  columns: {
    byId: Record<string, Column>;
    allIds: string[];
  };
  cards: {
    byId: Record<string, Card>;
    allIds: string[];
  };
}

const initialState: KanbanState = {
  columns: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: [],
  },
};

const reducer = (
  state: KanbanState,
  action: KanbanContextAction
): KanbanState => {
  switch (action.type) {
    case "SET_BOARD": {
      const { board } = action.payload;
      const boardColumnsById = objFromArray(board.columns);
      const boardCardsById = objFromArray(board.cards);
      return {
        ...state,
        columns: {
          byId: boardColumnsById,
          allIds: Object.keys(boardColumnsById),
        },
        cards: {
          byId: boardCardsById,
          allIds: Object.keys(boardCardsById),
        },
      };
    }
    case "MOVE_CARD":
      {
        const { cardId, position, columnId } = action.payload;
        const sourceColumnId = state.cards.byId[cardId].columnId;

        if (sourceColumnId === undefined) {
          return { ...state };
        }

        state.columns.byId[sourceColumnId].cardIds = state.columns.byId[
          sourceColumnId
        ].cardIds.filter((_cardId) => _cardId !== cardId);

        if (columnId !== undefined) {
          state.cards.byId[cardId].columnId = columnId;
          state.columns.byId[columnId].cardIds.splice(position, 0, cardId);
        } else {
          state.columns.byId[sourceColumnId].cardIds.splice(
            position,
            0,
            cardId
          );
        }
      }
      return { ...state };
    default:
      return { ...state };
  }
};

const KanbanContext = createContext<{
  state: KanbanState;
  dispatch: React.Dispatch<KanbanContextAction>;
}>({
  state: { ...initialState },
  dispatch: () => null,
});

export const KanbanProvider: React.FC = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <KanbanContext.Provider value={value}>{children}</KanbanContext.Provider>
  );
};

export function useKanbanState() {
  const { state } = useContext(KanbanContext);
  return state;
}

export function useKanbanDispatch() {
  const { dispatch } = useContext(KanbanContext);
  return dispatch;
}

export default KanbanContext;
