import markerSDK from "@marker.io/browser";
import type { AppProps } from "next/app";

import { LanguageProvider } from "components/LanguageProvider";
import { Providers } from "components/Providers";

import { isStaging } from "utils/domain-check";

function Application({ Component, pageProps }: AppProps) {
  if (isStaging()) {
    (async () => {
      try {
        const widget = await markerSDK.loadWidget({
          destination: "6172baa16245452657d556e5",
        });
        widget.show();
      } catch (error: unknown) {
        console.error(error);
      }
    })();
  }

  return (
    <Providers>
      <LanguageProvider>
        <Component {...pageProps} />
      </LanguageProvider>
    </Providers>
  );
}

export default Application;
