import { ActionMap } from "types/action-map";

export enum ChatActionTypes {
  SetActiveThread = "setActiveThread",
  SetHideSidebar = "setHideSidebar",
}

interface ChatPayload {
  [ChatActionTypes.SetActiveThread]: {
    threadId: string;
  };
  [ChatActionTypes.SetHideSidebar]: {
    hideSidebar: boolean;
  };
}

export type ChatActions = ActionMap<ChatPayload>[keyof ActionMap<ChatPayload>];
