// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objFromArray = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T = "id"
): Record<string, T> =>
  arr.reduce<Record<string, T>>((acc, curr) => {
    const newKey = curr[key] as string;
    acc[newKey] = curr;
    return acc;
  }, {});
