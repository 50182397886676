import { Routes } from "@prisma/client";
import React, { createContext, useEffect, useReducer } from "react";
import type { FC } from "react";

import { AuthContextAction } from "./AuthContextActions";
import { restoreSettings } from "./SettingsContext";

import * as AuthRequests from "requests/auth";
import { getOrganizationSubscription } from "requests/payment";

import {
  ForgotPasswordParams,
  User,
  SignInParams,
  ForgotPasswordResetParams,
  ChangePasswordParams,
  MenuId,
  ActionId,
  RegisterParams,
  ConfirmCodeParams,
  Role,
  ResendCodeParams,
  SubscriptionPlanInfo,
  SubscriptionConfirmParams,
  InitialMFA,
} from "types/api";

/**
 * Type of AuthContext state
 */
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  mfaUserSession: string | null;
  mfaType: "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null;
  organizationId: string | null;
  menus: MenuId[];
  actions: ActionId[];
  roles: Role[];
  routes: Routes[];
  subscription: SubscriptionPlanInfo | "loading" | null;
}

/**
 * Extended state with functions to mutate state
 */
interface AuthContextState extends State {
  login: (body: SignInParams) => Promise<string | undefined>;
  logout: () => Promise<void>;
  forgotPassword: (body: ForgotPasswordParams) => Promise<void>;
  forgotPasswordReset: (body: ForgotPasswordResetParams) => Promise<void>;
  newPassword: (body: ChangePasswordParams) => Promise<void>;
  register: (body: RegisterParams) => Promise<void>;
  confirmCode: (body: ConfirmCodeParams) => Promise<void>;
  confirmMFACode: (body: InitialMFA) => Promise<string | undefined>;
  switchOrganization: (organizationId: string | null) => void;
  resendConfirmationEmail: (body: ResendCodeParams) => Promise<void>;
  subscribeComplete: (subscription: SubscriptionPlanInfo) => void;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  mfaUserSession: null,
  mfaType: null,
  organizationId: null,
  actions: [],
  menus: [],
  roles: [],
  routes: [],
  subscription: "loading",
};

const reducer = (state: State, action: AuthContextAction): State => {
  switch (action.type) {
    case "INITIALIZE": {
      const {
        isAuthenticated,
        user,
        actions,
        menus,
        roles,
        subscription,
        routes,
      } = action.payload;

      const settings = restoreSettings();

      let orgID = null;

      if (user?.organization_id) {
        orgID = user.organization_id;
      }
      if (settings?.isViewAs) {
        orgID = settings.isViewAs;
      }

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        actions,
        menus,
        roles,
        routes,
        organizationId: orgID,
        subscription,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        subscription: "loading",
        mfaUserSession: null,
        mfaType: null,
      };
    }
    case "NEED_MFA":
      return {
        ...state,
        mfaUserSession: action.payload.session,
        mfaType: action.payload.mfaType,
      };
    case "LOGOUT":
      return {
        ...initialState,
        isInitialized: true,
      };
    case "REGISTER":
      return { ...state };
    case "VERIFY_CODE":
      return { ...state };
    case "RESEND_CODE":
      return { ...state };
    case "PASSWORD_RECOVERY":
      return { ...state };
    case "PASSWORD_RESET":
      return { ...state };
    case "NEW_PASSWORD":
      return { ...state };
    case "SWITCH_ORGANIZATION":
      return { ...state, organizationId: action.payload.organizationId };
    case "SUBSCRIBE_COMPLETE":
      return { ...state, subscription: action.payload.subscription };
    default:
      return { ...state };
  }
};

const AuthContext = createContext<AuthContextState>({
  ...initialState,
  login: async () => Promise.resolve(undefined),
  logout: async () => Promise.resolve(),
  forgotPassword: async () => Promise.resolve(),
  forgotPasswordReset: async () => Promise.resolve(),
  newPassword: async () => Promise.resolve(),
  register: async () => Promise.resolve(),
  confirmCode: async () => Promise.resolve(),
  confirmMFACode: async () => Promise.resolve(undefined),
  switchOrganization: () => {
    // Do nothing
  },
  resendConfirmationEmail: async () => Promise.resolve(),
  subscribeComplete: async () => Promise.resolve(),
});

export const AuthProvider: FC = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const currentSession = await AuthRequests.fetchCurrentSession();
        let subscription = null;

        if (currentSession.user.organization_id) {
          try {
            subscription = await getOrganizationSubscription(
              currentSession.user.organization_id
            );
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        const AccumulatedRoutePermissions: Routes[] = [];
        currentSession.roles.forEach((role) => {
          role.routes.forEach((route) => {
            if (!AccumulatedRoutePermissions.includes(route))
              AccumulatedRoutePermissions.push(route);
          });
        });

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: currentSession.user,
            menus: currentSession.menus,
            actions: currentSession.actions,
            roles: currentSession.roles,
            routes: AccumulatedRoutePermissions,
            subscription,
          },
        });
      } catch (error: unknown) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            menus: [],
            actions: [],
            roles: [],
            routes: [],
            subscription: null,
          },
        });
      }
    };

    initialize();
  }, [state.isAuthenticated]);

  const login = async (body: SignInParams): Promise<string> => {
    const { valid, status, userSession, mfaType } = await AuthRequests.login(
      body
    );
    if (status === "NEED_MFA" && userSession && mfaType) {
      dispatch({
        type: "NEED_MFA",
        payload: { session: userSession, mfaType },
      });
    } else {
      dispatch({
        type: "LOGIN",
      });
    }

    return valid ? (status as string) : "";
  };

  const logout = async (): Promise<void> => {
    await AuthRequests.logout();
    dispatch({
      type: "LOGOUT",
    });
  };

  const forgotPassword = async (body: ForgotPasswordParams) => {
    await AuthRequests.forgotPassword(body);
    dispatch({
      type: "PASSWORD_RECOVERY",
    });
  };

  const forgotPasswordReset = async (body: ForgotPasswordResetParams) => {
    await AuthRequests.resetPassword(body);
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  const newPassword = async (body: ChangePasswordParams) => {
    await AuthRequests.createNewPassword(body);
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  const register = async (body: RegisterParams) => {
    await AuthRequests.register(body);
    dispatch({
      type: "REGISTER",
    });
  };

  const confirmCode = async (body: ConfirmCodeParams) => {
    await AuthRequests.confirmCode(body);
    dispatch({
      type: "VERIFY_CODE",
    });
  };

  const confirmMFACode = async (body: InitialMFA): Promise<string> => {
    let valid = false;
    let status = "";
    if (state.mfaUserSession && state.mfaType) {
      const loginState = await AuthRequests.confirmMfaCode({
        userSession: state.mfaUserSession,
        mfaType: state.mfaType,
        mfaCode: body.mfaCode,
        email: body.email,
      });
      valid = loginState.valid;
      status = loginState.status;
    }

    dispatch({
      type: "LOGIN",
    });
    return valid ? status : "";
  };

  const switchOrganization = (organizationId: string | null) => {
    dispatch({
      type: "SWITCH_ORGANIZATION",
      payload: { organizationId },
    });
  };

  const resendConfirmationEmail = async (body: ResendCodeParams) => {
    await AuthRequests.resentConfirmationEmail(body);
    dispatch({
      type: "RESEND_CODE",
    });
  };

  const subscribeComplete = (subscription: SubscriptionConfirmParams) => {
    dispatch({
      type: "SUBSCRIBE_COMPLETE",
      payload: { subscription },
    });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      login,
      logout,
      forgotPassword,
      forgotPasswordReset,
      newPassword,
      register,
      confirmCode,
      confirmMFACode,
      switchOrganization,
      resendConfirmationEmail,
      subscribeComplete,
    }),
    [state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
