import axios, { AxiosError, AxiosRequestConfig } from "axios";
import AxiosMockAdapter from "axios-mock-adapter";

import { ApiError } from "types/api";

// The main axios client
export const axiosClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_URL ?? ""}/api/`,
});

// Fetcher for user with useSWR
export const fetcher = async <T>(url: string, config?: AxiosRequestConfig) => {
  try {
    const response = await axiosClient.get<T>(url, config);
    return response.data;
  } catch (error: unknown) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
};

// Refresh the users token if expired
axiosClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiError>) => {
    const originalRequest = error.config;

    // Only refresh if we need to
    if (
      error.response?.status === 401 &&
      error.response.data.message === "Token expired"
    ) {
      return axios.post("/api/auth/refresh").then((res) => {
        if (res.status === 200) {
          return axios(originalRequest);
        }
        window.location.href = "/?error=token_expired";
        return null;
      });
    }

    return Promise.reject(error);
  }
);

export const mock = new AxiosMockAdapter(axiosClient, {
  delayResponse: 200,
  onNoMatch: "passthrough",
});
