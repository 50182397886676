import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { makeStyles, createStyles } from "@mui/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SnackbarProvider } from "notistack";
import React from "react";
import { SWRConfig } from "swr";

import { fetcher } from "clients/axios";
import { createTheme } from "constants/theme";
import { AuthProvider } from "contexts/AuthContext";
import { ChatProvider } from "contexts/ChatContext";
import { KanbanProvider } from "contexts/KanbanContext";
import { SettingsProvider } from "contexts/SettingsContext";
import useSettings from "hooks/useSettings";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.default,
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#__next": {
        height: "100vh",
        width: "100vw",
      },
    },
  })
);

const CssBaselineStyled: React.FC = () => {
  const classes = useStyles();

  return <CssBaseline classes={classes} />;
};

const ThemedContent: React.FC = ({ children }) => {
  const { settings } = useSettings();

  const theme = createTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaselineStyled />
      {children}
    </ThemeProvider>
  );
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY ?? "");

export const Providers: React.FC = ({ children }) => (
  <SnackbarProvider
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    dense
    maxSnack={3}
  >
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <ChatProvider>
                <KanbanProvider>
                  <SWRConfig value={{ fetcher }}>
                    <ThemedContent>{children}</ThemedContent>
                  </SWRConfig>
                </KanbanProvider>
              </ChatProvider>
            </Elements>
          </AuthProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </SnackbarProvider>
);
