import React, { createContext, useEffect, useState } from "react";

import { THEMES, ThemeType } from "constants/themes";

export interface Settings {
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: ThemeType;
  isViewAs?: string;
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

const initialSettings: Settings = {
  responsiveFontSizes: true,
  roundedCorners: false,
  theme: THEMES.LIGHT,
  isViewAs: undefined,
};

export const restoreSettings = (): Settings | null => {
  let settings: Settings | null = null;

  try {
    const storedData: string | null = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData) as Settings | null;
    } else {
      settings = {
        ...initialSettings,
        theme: window.matchMedia("(prefers-color-scheme: dark)").matches
          ? THEMES.DARK
          : THEMES.LIGHT,
      };
    }
  } catch (err: unknown) {
    console.error(err);
    /*
     * If stored data is not a stringified JSON this will fail,
     * that's why we catch the error
     */
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => {
    // Do nothing
  },
});

export const SettingsProvider: React.FC = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  const value = React.useMemo(
    () => ({
      settings,
      saveSettings,
    }),
    [settings]
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
