import { axiosClient } from "clients/axios";

import {
  SignInResponse,
  SignInParams,
  ChangePasswordParams,
  ChangePasswordResponse,
  CurrentSession,
  ForgotPasswordParams,
  ForgotPasswordResetParams,
  ForgotPasswordResponse,
  ForgotPasswordResetResponse,
  RegisterParams,
  RegisterResponse,
  ConfirmCodeParams,
  ConfirmCodeResponse,
  ResendCodeParams,
  ResendCodeResponse,
  VerifyMFA,
  CancelParams,
  CancelResponse,
} from "types/api";
import {
  MfaSetupParams,
  MfaSetupResponse,
  MfaUpdateParams,
  MfaUpdateResponse,
  SMSMfaSetupParams,
} from "types/api/mfa";

export const createNewPassword = async (body: ChangePasswordParams) => {
  const response = await axiosClient.post<ChangePasswordResponse>(
    "auth/change-password",
    body
  );
  return response.data;
};

export const userProfileCreateNewPassword = async (
  body: ChangePasswordParams
) => {
  const response = await axiosClient.post<ChangePasswordResponse>(
    "auth/user-profile-change-password",
    body
  );
  return response.data;
};

export const login = async (body: SignInParams) => {
  const response = await axiosClient.post<SignInResponse>("auth/login", body);
  return response.data;
};

export const fetchCurrentSession = async () => {
  const response = await axiosClient.get<CurrentSession>("auth/login");
  return response.data;
};

export const logout = async () => {
  await axiosClient.post("auth/logout");
};

export const forgotPassword = async (body: ForgotPasswordParams) => {
  const response = await axiosClient.post<ForgotPasswordResponse>(
    "auth/forgot-password",
    body
  );
  return response.data;
};

export const resetPassword = async (body: ForgotPasswordResetParams) => {
  const response = await axiosClient.post<ForgotPasswordResetResponse>(
    "auth/forgot-password-reset",
    body
  );
  return response.data;
};

export const register = async (body: RegisterParams) => {
  const response = await axiosClient.post<RegisterResponse>(
    "auth/register",
    body
  );
  return response.data;
};

export const cancelRequest = async (body: CancelParams) => {
  const response = await axiosClient.post<CancelResponse>(
    "admin/request-cancellation",
    body
  );
  return response.data;
};

export const confirmCode = async (body: ConfirmCodeParams) => {
  const response = await axiosClient.post<ConfirmCodeResponse>(
    "auth/confirm-code",
    body
  );
  return response.data;
};

export const confirmMfaCode = async (body: VerifyMFA) => {
  const response = await axiosClient.post<SignInResponse>(
    "auth/mfa-verification",
    body
  );
  return response.data;
};

export const updateMfa = async (params: MfaUpdateParams) => {
  const response = await axiosClient.post<MfaUpdateResponse>(
    `auth/mfa/mfa`,
    params
  );

  return response.data;
};

export const setupMfa = async (params: MfaSetupParams) => {
  const response = await axiosClient.post<MfaSetupResponse>(
    `auth/mfa/mfa-setup`,
    params
  );

  return response.data;
};

export const setupSMSMfa = async (params: SMSMfaSetupParams) => {
  const response = await axiosClient.post<MfaSetupResponse>(
    `auth/mfa/sms-mfa-setup`,
    params
  );

  return response.data;
};

export const enableSMSMfa = async (params: MfaSetupParams) => {
  const response = await axiosClient.post<MfaSetupResponse>(
    `auth/mfa/enable-sms-mfa`,
    params
  );

  return response.data;
};

export const resentConfirmationEmail = async (body: ResendCodeParams) => {
  const response = await axiosClient.post<ResendCodeResponse>(
    "auth/resend-code",
    body
  );

  return response.data;
};

export const sendSMSCode = async () => {
  const response = await axiosClient.post<MfaSetupResponse>(
    `auth/mfa/send-sms`
  );

  return response.data;
};
