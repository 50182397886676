/* eslint-disable no-case-declarations */
import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";

import { ChatActions, ChatActionTypes } from "./ChatContextActions";

interface State {
  activeThreadId?: string;
  hideSidebar?: boolean;
}

interface ChatContextValue extends State {
  dispatch: React.Dispatch<ChatActions>;
  setActiveThread: (threadKey: string) => void;
  setHideSidebar: (hideSidebar: boolean) => void;
}

const initialState: State = {
  activeThreadId: undefined,
  hideSidebar: false,
};

function reducer(state: State, action: ChatActions): State {
  switch (action.type) {
    case ChatActionTypes.SetActiveThread:
      return {
        ...state,
        activeThreadId: action.threadId,
      };
    case ChatActionTypes.SetHideSidebar:
      return {
        ...state,
        hideSidebar: action.hideSidebar,
      };
    default:
      return state;
  }
}

const ChatContext = createContext<ChatContextValue>({
  ...initialState,
  dispatch: () => null,
  setActiveThread: () => null,
  setHideSidebar: () => null,
});

export const ChatProvider: React.FC = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const setActiveThread = useCallback((threadId: string) => {
    dispatch({
      type: ChatActionTypes.SetActiveThread,
      threadId,
    });
  }, []);

  const setHideSidebar = useCallback((hideSidebar: boolean) => {
    dispatch({
      type: ChatActionTypes.SetHideSidebar,
      hideSidebar,
    });
  }, []);

  const value = React.useMemo(
    () => ({
      ...state,
      setActiveThread,
      setHideSidebar,
      dispatch,
    }),
    [state, setActiveThread, setHideSidebar, dispatch]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export function useChatDispatch() {
  const { dispatch } = useContext(ChatContext);
  return dispatch;
}
export default ChatContext;
